.schedule-page ion-content ion-toolbar {
	--background: translucent;
}

.schedule-page ion-content {
	--background: #f9f9f9;
	padding: 2rem 0;
	text-align: center;
}

.schedule-page ion-content ion-spinner {
	margin: 1rem auto;
}

@media only screen and (min-width: 768px) {
	.schedule-page ion-content .schedule {
		max-width: 50vw;
		margin: 0 auto;
	}
}

.schedule-page ion-card {
	box-shadow: none;
	margin: .5rem 1rem;
}

.schedule-page ion-card ion-card-content {
	text-align: left;
}

.schedule-page ion-card ion-card-content h3 {
	font-size: 1.3rem !important;
	font-weight: 900 !important;
	color: #000;
	margin: 0 0 .5rem 0 !important;
	text-align: left;
}

.schedule-page ion-card ion-card-content p {
	margin: 0;
	font-weight: 500;
}

.schedule-page ion-card ion-card-content p.bold {
	font-size: .9rem;
	color: #575756;
	font-weight: 600;
}

.schedule-page ion-card ion-card-content p.details {
	font-size: .9rem;
	margin: .5rem 0;
	color: #575756;
}

.schedule-page ion-card ion-card-content p.time {
	font-size: .8rem;
	margin-bottom: .5rem;
	color: #575756;
}

.schedule-page ion-card ion-card-content p.details:last-child {
	margin-bottom: 0;
}

.schedule-page ion-card ion-card-content p img {
	position: relative;
	top: 5px;
	margin: 0 .2rem 0 0;
	width: .9rem;
	border-radius: unset;
}

.bold {
	font-weight: 600;
}