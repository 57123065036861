.profile-page ion-content ion-toolbar {
  --background: translucent;
}

.profile-page ion-content {
  text-align: center;
}

.profile-page ion-content h2 {
  text-transform: uppercase;
}

.profile-page ion-content .profile-container {
  max-width: 80vw;
  text-align: center;
  margin: 0 auto;
  display: block;
  padding: .2rem 0;
  font-size: 1rem;
}

.profile-page ion-content .skeleton {
  display: block;
  background: #ccc;
  width: 100%;
  padding: .5rem;
}

@media only screen and (min-width: 768px) {
	.profile-page ion-content .profile-container {
		max-width: 50vw;
		margin: 0 auto;
	}
}