ion-modal .modal-wrapper .ion-page {
	padding: 4rem 1rem 1rem 1rem;
}

ion-modal .modal-wrapper .ion-page .owl {
	width: 60vw;
	max-width: 50%;
	margin: 2rem auto 0 auto;
	position: relative;
	display: block;
}

ion-modal .modal-wrapper .ion-page ion-select {
	position: relative;
	margin: -4rem auto 0 auto;
	width: 100%;
}

ion-modal .modal-wrapper .ion-page ion-select, ion-modal .modal-wrapper .ion-page ion-select-option {
	color: #424242;
}