.nointernet img {
  margin: 1rem auto;
}

.nointernet h4 {
  margin: 0 auto;
  text-align: center;
  font-size: 1rem;
  padding: 0 2rem;
}

@media only screen and (min-width: 768px) {
	.nointernet h4 {
		max-width: 50vw;
	}
}