.personality-page ion-content ion-toolbar {
	--background: translucent;
}

.personality-page ion-content {
	--background: #f9f9f9;
	text-align: center;
}

.personality-page ion-content .personality {
	width: 100%;
	height: auto;
	margin: 0 auto;
	padding: 1rem 0;
	background-size: cover;
	background-position: center center;
}

.personality-page ion-content ion-toolbar ion-buttons ion-icon {
	color: #fff;
}

.personality-page ion-icon {
	font-size: 1.5rem;
	margin-right: .5rem;
	cursor: pointer;
}

.personality-page ion-content h3 {
	text-align: center;
	font-size: 1.4rem;
	font-weight: bold;
	color: #000;
	margin: 0 1rem .2rem 1rem;
	text-transform: uppercase;
}

.personality-page ion-content h3.user-manual {
	margin: 1rem 0;
}

.personality-page ion-content h3.type {
	margin-top: .5rem;
}

.personality-page ion-content h4 {
	margin: 0;
}

.personality-page ion-content img {
	margin: 1rem 0 .5rem 0;
	border-radius: 50%;
	width: 10rem;
}

.personality-page ion-content p {
	font-weight: 300;
	text-align: left;
	margin: 1rem;
}

.personality-page ion-content p.adjectives { 
	margin: .25rem 1rem;
	text-align: center;
}

.personality-page ion-content p.description { 
	margin: .5rem 1rem;
	text-align: center;
}

.personality-page ion-content p.errors {
	color: #6b2c91;
	font-weight: bold;
	text-align: center;
}

.personality-page ion-button {
	text-transform: uppercase;
	margin: 1rem auto;
}

.personality-page ion-content ion-slide {
	background: #fff;
	padding: 1rem 1rem 1.5rem 1rem;
}

.personality-page ion-content .input-block {
	padding: 0 1rem;
	width: 100%;
}

.personality-page ion-content .input-block p {
	margin: 0 0 .5rem 0;
	width: 100%;
	display: block;
	font-size: 1rem;
}

.personality-page ion-content .input-block p.section {
	text-transform: uppercase;
	margin-bottom: 1rem;
	font-weight: bold;
}

.personality-page ion-content .input-block p.section span {
	float: right;
	color: #666;
}

.personality-page ion-content .input-block ion-input, .personality-page ion-content .input-block ion-textarea {
	margin: 0 0 1rem 0;
	width: 100%;
	--color: #000;
	--background: #f9f9f9;
	--padding-start: 1rem;
	font-weight: 300;
	font-size: .9rem;
	text-align: left;
	display: block;
}

.personality-page ion-content .input-block ion-button {
	margin: 0 0 1.5rem 0;
}

.personality-page ion-content .input-block ion-button.solo {
	margin: 6rem 0;
}

.personality-page ion-content .section-block p.section {
	text-transform: uppercase;
	margin: 1rem 1rem 0.5rem 1rem;
	font-weight: bold;
	font-size: 1.1rem;	
}

.personality-page ion-content .section-block p.section:after {
	position: relative;
	content: '';
	border-bottom: 1px solid #000;
}

.personality-page ion-content .section-block p.section:first-child {
	margin-top: .5rem;
}

.personality-page ion-content .section-block p.description {
	margin: 0 1rem 1rem 1rem;
	text-align: left;
}

@media only screen and (min-width: 768px) {
	.personality-page ion-content .personality .content {
		max-width: 50vw;
		margin: 0 auto;
	}
}