.after-hours-page ion-content ion-toolbar {
  --background: translucent;
}

.after-hours-page ion-content .map {
    height: 60vh;
}

.after-hours-page ion-content p {
  margin: .8rem 0;
}

.after-hours-page ion-content p.intro {
  margin: .25rem 1rem;  
  font-size: .9rem;
}

.after-hours-page ion-content p.intro:first-child {
  margin-top: 1rem;
}


.after-hours-page ion-content .map .address, .after-hours-page ion-content .map .directions {
  width: 50%;
  display: inline-block;
  padding: 1rem;
  font-size: .9rem;
}

.after-hours-page ion-content .map .address {
  text-align: left;
  vertical-align: top;
  padding: 1rem 0;
}

.after-hours-page ion-content .map .address p {
  margin: 0 1rem;
  font-size: .9rem;
  line-height: 1rem;
  text-transform: uppercase;
}

.after-hours-page ion-content .map .address p.bold {
  margin-bottom: .4rem; 
}

.after-hours-page ion-content .map .directions {
  text-align: right;
  text-transform: uppercase;
  vertical-align: top;
}

@media only screen and (min-width: 768px) {
  .after-hours-page ion-content p.intro {
		max-width: 50vw;
		margin: 0 auto;
  }

  .after-hours-page ion-content .map {
    max-width: 50vw;
    margin: 1rem auto;
  }

  .after-hours-page ion-content .map .address p {
    margin: 0;
  }
}